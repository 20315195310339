import React, { useEffect, useState } from "react"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { shuffleArray, getUnrelatedReviews, getHearFromHeadingAndBlurbObject, getPostObj, isFacewallTextGeneric } from "../utils/utils"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo";
import Button from '@bit/azheng.joshua-tree.button';
import HeadingBlurbMedia from "../components/BodyElements/HeadingBlurbMedia"
import MarkdownSection from "../components/BodyElements/MarkdownSection"
import FeaturedList from "../components/BodyElements/FeaturedList"
import GenericSection from "../components/BodyElements/GenericSection"
import ReviewRow from "../components/ReviewRow"
import CloseIcon from "../components/CloseIcon"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import FixedFacewall from "../components/carousel/FixedFacewall"
import ReviewRowsSection from "../components/ReviewRowsSection"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"

import { Dialog, setHotkeysDialogProps } from "@blueprintjs/core"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import stars from "../images/stars.svg"


var classNames = require("classnames")

var showdown = require("showdown")

const SingleProcedure = ({ data, pageContext, location }) => {

  var post;
  var language;
  let postObj = getPostObj(pageContext, data.allProcedures.nodes[0], data.allProceduresEs.nodes[0]);
  post = postObj.post;
  language = postObj.language;

  // SDOS classNames
  const HeadingBlurbMediaDI = post.name === "Dental Implants" ? "Dental Implants" : "";
  const anesthesiaDI = post.name === "Dental Implants" ? "di-anesthesia" : "";
  const ctaDI = post.name === "Dental Implants" ? "di-cta" : "";

  let getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  let getStartedDataEs = data.allTemplateSettingsJson.nodes[0].getStartedSpanish

  let procedureGetStartedData = data.allProcedures.nodes[0].getStarted

  let getStartedBtns = [...getStartedData.buttons, ...procedureGetStartedData.buttons];


  // // S DOS Unique Procedure Get Started Buttons
  // try {
  //   if (language === "en" && data.allProcedures && data.allProcedures.nodes.length > 0 && data.allProcedures.nodes[0].getStarted) {
  //     getStartedData = data.allProcedures.nodes[0].getStarted
  //   } else if (language === "es" && data.allProceduresEs && data.allProceduresEs.nodes.length > 0 && data.allProceduresEs.nodes[0].getStarted) {
  //     getStartedDataEs = data.allProceduresEs.nodes[0].getStarted
  //   }
  // } catch (err) {
  //   console.error(err)
  // }

  var relatedReviews = []
  var allReviews = []

  if (data.relatedReviews.nodes) relatedReviews = data.relatedReviews.nodes
  if (data.allReviews.nodes) allReviews = data.allReviews.nodes

  shuffleArray(relatedReviews)

  const originalReviewCount = relatedReviews.length

  var unrelatedReviews = getUnrelatedReviews(
    relatedReviews,
    allReviews,
    "title"
  )

  shuffleArray(unrelatedReviews)

  var fixedFacewallReviews = []
  var reviewRowsReviews = []

  // Limit leftover reviews to 5, all reviews per page to 9
  var relatedReviewsLength = relatedReviews.length
  if (relatedReviewsLength > 4 + 5) {
    relatedReviewsLength = 9
  }

  // If longer than 4, Split reviews into facewall (4) and review rows (up to 5)
  if (originalReviewCount > 4) {
    fixedFacewallReviews = relatedReviews.slice(0, 4)
    reviewRowsReviews = relatedReviews.slice(4, relatedReviewsLength)
  }

  // Else, fill facewall with unrelated reviews until 4 long
  else if (originalReviewCount < 4) {
    fixedFacewallReviews = relatedReviews
    while (fixedFacewallReviews.length < 4) {
      fixedFacewallReviews.push(unrelatedReviews.pop())
    }
  } else {
    fixedFacewallReviews = relatedReviews
  }

  const converter = new showdown.Converter()
  var text = post.body
  var cta = post.ctaSection
  var html = converter.makeHtml(text)

  var ctaHtml = converter.makeHtml(cta)

  function createCtaHtml() {
    return { __html: ctaHtml }
  }

  function createHtml(html) {
    return { __html: html }
  }

  function BodySection(props) {
    if (props.type === "textSection") {
      return (
        <MarkdownSection
          textSection={props.textSection}
          sideColumnIs={5}
          paddingBottom={props.paddingBottom ? props.paddingBottom : null}
          paddingTop={props.paddingTop ? props.paddingBottom : null}
        />
      )
    } else if (props.type === "headingBlurbMedia") {
      return (
        <HeadingBlurbMedia {...props} customStyle={HeadingBlurbMediaDI} />
      )
    } else if (props.type === "featuredList") {
      return (
        <FeaturedList
          listContent={props}
          headingSideColumnIs={5}
          listSideColumnIs={5}
        />
      )
    } else if (props.type === "fixedFacewall") {

      if (fixedFacewallReviews.length >= 4 && language !== "es") {
        return (
          <FixedFacewall
            blurb={<p>{fixedFacewallBlurb}</p>}
            heading={fixedFacewallHeading}
            sideColumnIs={1}
            middleColumnsis={1}
            reviews={fixedFacewallReviews}
          />
        )
      }
    } else if (props.type === "genericSection") {
      return <GenericSection {...props} />
    }

  }


  let bodySections;

  // MOS if anesthesia page, move modal section up
  if (location.pathname === "/anesthesia-options/") {
    bodySections = <MOSTextSection {...post.bodySections} />
  } else {
      bodySections = post.bodySections.map(section => {
        return <BodySection {...section} />
      })
  }
  

  var mobileMainPhotoPublicId = post.mainPhotoMobilePublicId;

  var headingAndBlurb = getHearFromHeadingAndBlurbObject(post, originalReviewCount);

  var fixedFacewallHeading = headingAndBlurb.heading;
  var fixedFacewallBlurb = headingAndBlurb.blurb;

  var procedureCopy;

  // A Tale of Two Templates
  if (!post.youtube && !post.mainPhotoPublicId) {
    procedureCopy =
      <section className="procedure procedure-body no-video top-section section white-back joshua-tree-content">
        {bodySections}
      </section>
  } else {
    procedureCopy =
      <>
        <div className="procedure-video-top-wrapper">
            <NuvoImage
              wrapperClassName="show-desktop-only"
              useAR
              cloudName="nuvolum"
              publicId={post.mainPhotoPublicId}
              className="show-desktop-only"
              width="auto"
              responsive
            ></NuvoImage>
            <NuvoImage
              wrapperClassName="show-desktop-only-inverse mobile-nav-spacer"
              useAR
              cloudName="nuvolum"
              publicId={mobileMainPhotoPublicId}
              className="show-desktop-only-inverse"
              width="auto"
              responsive
            ></NuvoImage>
          <div className="columns procedure-heading-container is-vcentered">
            <div className="column is-2"></div>

            <div className="column">

            <div className="column is-4 procedures-theater-button is-hidden-tablet">
              {post.youtube && <TheaterVideo
                language={language}
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
                hasWatchVideo={true}
                buttonClass="contained"
              />}
            </div>

              <div className={`procedure-video-top-headings single-procedure-heading ${post.whiteTopHeadings ? "white-top-headings" : ""}`}>
                <h1 dangerouslySetInnerHTML={createHtml(post.fullName || post.name)}></h1>
                <h2 style={{margin: 0}}>{post.subheading}</h2>
              </div>
            </div>

            <div className="column is-4 procedures-theater-button is-hidden-mobile">
              {post.youtube && <TheaterVideo
                language={language}
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
                hasWatchVideo={true}
                buttonClass="contained"
              />}
            </div>
            <div className="column is-2"></div>
          </div>
          
        </div>

        <div className={language === "es" ? "procedure-body procedure-body-es" : "procedure-body"} >{bodySections}</div>

      </>
  }

  var backUrl = "/" + data.allUniquePagesJson.nodes[0].title;

  let facewallClasses = classNames("procedure-facewall", {
    "generic": isFacewallTextGeneric(post, originalReviewCount)
  })
  
  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO 
          title={post.metaTitle} 
          description={post.metaDescription} 
          pathname={location.pathname} 
          schemaPage={post.schemaPage ? post.schemaPage : null} 
          youtubeSchemas={pageContext.youtubeSchemasJson}
        />

        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/servicios/" : backUrl}
          className={post.title === "stem-cells/" ? "text-white" : ""}
        />
        <div>
          {procedureCopy}
        </div>
        {post.procedureLevel === "featured" && language !== "es" &&  <div className={`single-procedure-anesthesia ${anesthesiaDI}`}><AnesthesiaModal hasInlink={location.pathname !== "/anesthesia-options/"} procedurePage /></div>}

        {/* S DOS Customization */}
        {post.title === "stem-cells/" &&
          <section className="body-section color-back media-coverage">
            <div className="columns has-text-centered">
              <div className="column">
                <h2>Media Coverage</h2>
              </div>
            </div>
            <Grid pages={[
              {
                "image": "SDOS/DEV/stemcells-media-1",
                "href": "https://www.10news.com/news/local-news/teenager-fighting-possible-future-diseases-with-wisdom-teeth"
              },
              {
                "image": "SDOS/DEV/stemcells-media-2",
                "href": "https://news.yahoo.com/harvesting-stem-cells-wisdom-teeth-012212988.html"
              }
            ]} rowsOf={2} />
          </section>
        }
        
        {fixedFacewallReviews.length >= 4 && language !== "es" && (
          <div className={facewallClasses}>
            <FixedFacewall
              blurb={<p>{fixedFacewallBlurb}</p>}
              heading={fixedFacewallHeading}
              sideColumnIs={1}
              middleColumnsis={1}
              reviews={fixedFacewallReviews}
              isGeneric={isFacewallTextGeneric(post, originalReviewCount)}
              button
              hideProcedureNames={!isFacewallTextGeneric(post, originalReviewCount)}
            />
          </div>
        )}

        {post.ctaSection && (
          <section
            className="body-sections section joshua-tree-content cta-with-button"
            style={{ paddingTop: "0" }}
          >
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createCtaHtml()}
              ></div>
              <div className="column is-5"></div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <Button buttonText="Get Started" href="/get-started/" />
              </div>
            </div>
          </section>
        )}

        {reviewRowsReviews.length > 0 && language !== "es" && (
          <ReviewRowsSection
            reviews={reviewRowsReviews}
            nameSingular={post.nameSingular}
            //paddingTop={post.ctaSection ? "" : "0"}
            // paddingBottom={"0"}
            classnames="di-reviews"
          />
        )}

        <div>
          <OffsetHeadingCTA
            colorBack={false}
            className={`single-procedure-cta ${ctaDI}`}
            sideColumnIs={4}
            headingJSX={
              <h3 className="has-text-centered-tablet">
                {language === "es"
                  ? getStartedDataEs.heading
                  : procedureGetStartedData.heading}
              </h3>
            }
            paragraphJSX={
              <div
              className="has-text-centered-tablet"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(language === "es" ? getStartedDataEs.blurb : procedureGetStartedData.blurb)
                )}
              ></div>
            }

            buttonText={language === "es" ? getStartedDataEs.buttonText : procedureGetStartedData.buttonText}
            buttonUrl={
              language === "es"
                ? getStartedDataEs.href
                : procedureGetStartedData.href
            }
            buttons={language === "es" ? getStartedDataEs.buttons : procedureGetStartedData.buttons}
          />
        </div>

      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query($title: String!, $procedureName: [String]) {
    allUniquePagesJson(filter: {template: {eq: "procedures"}}) {
      nodes {
        title
      }
    }
    allTemplateSettingsJson(filter: {templateType: {eq: "single-procedure"}}) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    allProcedures: allProceduresJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        fullName
        subheading
        whiteTopHeadings
        nameSingular
        youtube
        procedureLevel
        bodySections {
          heading
          paragraph
          textSection
          type
          youtube
          youtubePhotoPublicId
          videoSideColumnIs
          headingSideColumnIs
          sideColumnIs
          paddingBottom
          paddingTop
          headingLevel
          headingLook
          colorBack
          genericSection {
            type
            isCentered
            heading
            headingType
            textAlign
            paddingBottom
            paddingTop
            buttons {
              button {
                buttonText
                href
                minimal
                contained
              }
            }
          }
        }
        getStarted {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          blurb
          heading
        }
        metaTitle
        metaDescription
        schemaPage
        mainPhotoPublicId
        mainPhotoMobilePublicId        
      }
    }
    allProceduresEs: allSpanishProceduresJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        subheading
        whiteTopHeadings
        nameSingular
        youtube
        procedureLevel
        bodySections {
          heading
          paragraph
          textSection
          type
          youtube
          youtubePhotoPublicId
          videoSideColumnIs
          headingSideColumnIs
          sideColumnIs
          paddingBottom
          paddingTop
          headingLevel
          headingLook
          genericSection {
            type
            isCentered
            heading
            headingType
            paddingBottom
            paddingTop
            buttons {
              button {
                buttonText
                href
                minimal
                contained
              }
            }
          }
        }
        getStarted {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          blurb
          heading
        }
        metaTitle
        metaDescription
        schemaPage
        mainPhotoPublicId
        mainPhotoMobilePublicId  
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: $procedureName } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    anesthesiaJson: allUniquePagesJson(filter: {title: {eq: "anesthesia-options/"}}) {
      nodes {
        anesthesiaTypes {
          blurb
          buttonOne {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          buttonThree {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          heading
          buttonTwo {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
        }
      }
    }
  }
`
// S DOS Customization
function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-3"></div>}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-8" : "is-8"
                }`}
            >
              <a target="_blank" rel="noopener noreferrer" className="hover-link" href={item.href}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={item.image}
                  width="auto"
                  responsive
                ></ImageMeta>
                <a target="_blank" rel="noopener noreferrer" href={item.href} className="standard-button contained image-button">
                  Learn More
                </a>
              </a>
            </div>
            {i === 0 && <div className="column is-2"></div>}
            {i === 1 && <div className="column is-3"></div>}
          </>
        )
      })}
    </div>
  )
}

// MOS move anesthesia up
const MOSTextSection = (props) => {
  return (
    <>
      <MarkdownSection
        textSection={props[0].textSection}
        sideColumnIs={5}
        paddingBottom={props[0].paddingBottom ? props[0].paddingBottom : null}
        paddingTop={props[0].paddingTop ? props[0].paddingBottom : null}
      />

      <div className={`single-procedure-anesthesia`}><AnesthesiaModal hasInlink={false} procedurePage /></div>

      <MarkdownSection
        textSection={props[1].textSection}
        sideColumnIs={5}
        paddingBottom={props[1].paddingBottom ? props[1].paddingBottom : null}
        paddingTop={props[1].paddingTop ? props[1].paddingBottom : null}
      />

      <MarkdownSection
        textSection={props[2].textSection}
        sideColumnIs={5}
        paddingBottom={props[2].paddingBottom ? props[2].paddingBottom : null}
        paddingTop={props[2].paddingTop ? props[2].paddingBottom : null}
      />
    </>
  )
};

function Grid(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRow key={`media-coverage-row-${i}`} items={row} />
  ))
}

export default SingleProcedure
